import { EducationStudentsReponse } from '@/models/education-students/classes/education-student-response'
import { CreateStudent } from '@/models/education-students/interfaces/education-create'
import { IEducationStudentsReponse } from '@/models/education-students/interfaces/education-student-response-interface'
import { Api } from './api'

class EducationStudentApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  parseEducationStudentResponse(
    students: IEducationStudentsReponse
  ): EducationStudentsReponse {
    return new EducationStudentsReponse(
      students.total,
      students.page,
      students.nPages,
      students.data
    )
  }

  public async getStudents(
    query: Record<string, string | boolean | number | Date | string[]>
  ): Promise<EducationStudentsReponse> {
    const response = await Api.get<IEducationStudentsReponse>(
      `${this.baseURL}/v1/education-students`,
      query
    )
    return this.parseEducationStudentResponse(response)
  }

  public async getTotal(
    query: Record<string, string | boolean | number | Date | string[]>
  ): Promise<number> {
    const response = await Api.get<number>(
      `${this.baseURL}/v1/education-students/count`,
      query
    )
    return response
  }

  public async createStudent(
    createStudentdto: CreateStudent
  ): Promise<CreateStudent> {
    const response = await Api.post<CreateStudent>(
      `${this.baseURL}/v1/education-students/create`,
      createStudentdto
    )
    return response
  }
}
export const EducationStudentAPI = new EducationStudentApi()
