import { EducationStudents } from './education-student'

export class EducationStudentsReponse {
    total: number
    page: number
    nPages: number
    data: EducationStudents[]
    constructor (
      total: number,
      page: number,
      nPages: number,
      data: EducationStudents[]
    ) {
      this.total = total
      this.page = page
      this.nPages = nPages
      this.data = data
    }
}
