import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModuleHeader = _resolveComponent("ModuleHeader")!
  const _component_CreateStudentForm = _resolveComponent("CreateStudentForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ModuleHeader, {
      title: "Nuevo Estudiante",
      showGoBack: true
    }),
    _createVNode(_component_CreateStudentForm)
  ], 64))
}