
import { defineComponent, ref } from 'vue'
import ModuleHeader from '@/components/commons/boxes/ModuleHeader.vue'
import CreateStudentForm from '@/views/education/students/components/CreateStudentForm.vue'
export default defineComponent({
  components: {
    ModuleHeader,
    CreateStudentForm
  },
  setup () {
    const a = ref<string>('')
    return {
      a
    }
  }
})
