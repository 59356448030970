
import { computed, defineComponent, onMounted, ref } from 'vue'
import FileUpload from 'primevue/fileupload'
import Divider from 'primevue/divider'
import { CoderplusAPI } from '@/api/coderplus.api'
import { Video, VideoCategory } from '@/models/coderplus/classes/Video'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { useCountriesStore } from '@/store/modules/commons/countries-store'
import { Country } from '@/models/countries/classes/Country'
import { CreateStudent } from '@/models/education-students/interfaces/education-create'
import { StudentsService } from '@/models/components/tables/enums'
import { StudentSubscriptions } from '@/models/education-students/classes/education-student'
import { EducationStudentAPI } from '@/api/education-student.api'
import { storeAPI } from '@/api/store.api'
import { CareerPlan } from '@/models/academic-definitions/classes/CareerPlan'
import { CareerPlanAPI } from '@/api/academic-definitions/career-plan-api'
export default defineComponent({
  components: {
    // FileUpload
  },
  props: {},
  setup () {
    const a = ref<string>('')
    const selectedFlags = ref<StudentSubscriptions[]>([])
    const imageName = ref<string>('')
    const imageFile = ref<any>()
    const base64 = ref<string>('')
    const countriesStore = useCountriesStore()
    const countries = computed<Country[]>(() => countriesStore.countriesSortedByNames)
    const userName = ref<string>('')
    const userlastName = ref<string>('')
    const userDNI = ref<string>('')
    const userEmail = ref<string>('')
    const userPhone = ref<string>('')
    const userCareer = ref<string>('')
    const userCountry = ref<Country>()
    const flags = ref<StudentSubscriptions[]>([])
    const toast = useToast()
    const data = new FormData()
    const careers = ref<CareerPlan[]>([])
    const careersSelected = ref<CareerPlan[]>([])

    onMounted(async () => {
      try {
        careers.value = await CareerPlanAPI.findAll()
      } catch (error) {
        console.log(error)
      }
    })

    const validateEmail = (email: string) => {
      // eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true
      } else {
        return false
      }
    }

    const resetInfo = () => {
      userName.value = ''
      userlastName.value = ''
      userDNI.value = ''
      userEmail.value = ''
      userPhone.value = ''
      userPhone.value = ''
      careersSelected.value = []
      userCountry.value = undefined
      base64.value = ''
      imageName.value = ''
      selectedFlags.value = []
    }

    const validateFields = () => {
      if (
        userName.value === '' ||
        userlastName.value === '' ||
        userDNI.value === '' ||
        validateEmail(userEmail.value) === false ||
        userPhone.value === '' ||
        careersSelected.value.length === 0 ||
        userCountry.value === null ||
        selectedFlags.value.length === 0 ||
        imageName.value === ''
      ) {
        return false
      }
      return true
    }

    const createStudent = async () => {
      if (validateFields()) {
        try {
          const fileRes = await storeAPI.uploadFileCloudinary(data)
          const student: CreateStudent = {
            email: userEmail.value,
            subscription: selectedFlags.value,
            activeServices: [],
            profile: {
              avatar: fileRes,
              firstName: userName.value,
              lastName: userlastName.value,
              country: userCountry.value!.code!,
              phone: userPhone.value,
              dni: userDNI.value,
              onCareers: careersSelected.value.map((e) => e.id)
            }
          }
          await EducationStudentAPI.createStudent(student)
          toast.add({
            summary: ToastSummaries.Success,
            detail: 'Usario creado correctamente',
            severity: ToastSeverities.Success,
            life: ToastLife.Default
          })
          resetInfo()
        } catch (error) {
          toast.add({
            summary: ToastSummaries.Warn,
            detail: 'Error al crear usuario, asegurese de ingresar la información correctamente.',
            severity: ToastSeverities.Warn,
            life: ToastLife.Default
          })
          console.log(error)
        }
      } else {
        toast.add({
          summary: ToastSummaries.Warn,
          detail: 'Asegurese de llenar todos los campos correctamente',
          severity: ToastSeverities.Warn,
          life: ToastLife.Default
        })
      }
    }

    return {
      imageName,
      userCountry,
      selectedFlags,
      countries,
      userName,
      userlastName,
      userDNI,
      userEmail,
      userPhone,
      userCareer,
      flags,
      base64,
      validateFields,
      imageFile,
      careers,
      createStudent,
      careersSelected,
      onFilePicked: (event: any) => {
        imageName.value = event.target.files[0].name
        imageFile.value = event.target.files[0]
        data.append('file', event.target.files[0], event.target.files[0].name)
        const reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = () => {
          if (reader.result !== null) {
            base64.value = reader.result.toString()
          }
        }
      }
    }
  }
})
